<template>
  <b-container fluid>
   <iq-card>
      <template v-slot:body>
       <ValidationObserver>
      <b-row>
      <b-col sm="4">
        <ValidationProvider name="commodity_id" vid="commodity_id" rules="required|min_value:1">
         <b-form-group
          class="row"
          label-cols-sm="4"
          label-for="commodity_id"
          slot-scope="{ valid, errors }"
          >
          <template v-slot:label>
            {{ $t('warehouse_config.crop_type') }} <span class="text-danger">*</span>
          </template>
          <b-form-select
            plain
            v-model="search.commodity_id"
            :options="commodityGroupList"
            id="commodity_id"
            :state="errors[0] ? false : (valid ? true : null)"
            >
            <template v-slot:first>
              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
          </b-form-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
       <b-col sm="4">
         <b-form-group
          class="row"
          label-cols-sm="4"
          label-for="crop_id"
          >
          <template v-slot:label>
            {{ $t('warehouse_config.crop_name') }} <span class="text-danger">*</span>
          </template>
          <b-form-select
            plain
            v-model="search.crop_id"
            :options="commodityNameList"
            id="crop_id"
            >
            <template v-slot:first>
              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
          </b-form-group>
      </b-col>
      <b-col>
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </b-col>
      </b-row>
      </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('warehouseFarmer.qcCertificate') }} </h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="QCCertificateData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">{{ data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(region_name)="data">
                      <span class="capitalize">{{ data.item.region_name }}</span>
                    </template>
                    <template v-slot:cell(distict_name)="data">
                      <span class="capitalize">{{ data.item.distict_name }}</span>
                    </template>
                    <template v-slot:cell(upzila_name)="data">
                      <span class="capitalize">{{ data.item.upzila_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_name)="data">
                      <span class="capitalize">{{ data.item.farmer_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_land_area)="data">
                      <span class="capitalize">{{ $n(data.item.farmer_land_area) }}</span>
                    </template>
                    <template v-slot:cell(store_date)="data">
                      <span class="capitalize">{{ data.item.store_date|dateFormat }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                     <b-button v-b-modal.modal-7 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    </template>
                  </b-table>
                   <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
      <b-modal id="modal-7" size="lg" :title="$t('warehouseFarmer.qcCertificate')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Detail :id="testId" :key="testId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { wareFarmerQCCertificateList, userStatus } from '../../../api/routes'
import Detail from './Detail'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'UiDataTable',
  components: {
    Detail,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.ExternalUserIrrigation.static.perPage,
        slOffset: 1
      },
      search: {
        commodity_id: 0,
        crop_id: 0
      },
      testId: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      QCCertificateData: [],
      commodityNameList: []
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('externalUserIrrigation.warehouseFarmer.qcCertificate') : this.$t('warehouse_service_performance.complain')
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    },
    listReload () {
      return this.$store.state.ExternalUserIrrigation.static.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.division'), class: 'text-left' },
          { label: this.$t('warehouse_config.region'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-left' },
          { label: this.$t('warehouse_service_performance.farmer_name'), class: 'text-left' },
         { label: this.$t('warehouse_service_performance.land_area'), class: 'text-left' },
         { label: this.$t('warehouse_service_performance.date'), class: 'text-left' },
          // { label: this.$t('globalTrans.status'), class: 'text-center' }
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'region_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouseName_bn' },
          { key: 'farmer_name_bn' },
          { key: 'farmer_land_area' },
          { key: 'store_date' },
          // { key: 'status' }
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouseName' },
          { key: 'farmer_name' },
          { key: 'farmer_land_area' },
          { key: 'store_date' },
          // { key: 'status' }
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
        })
    },
    commodityGroupList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.commodity_id': function (newVal, oldVal) {
      this.commodityNameList = this.getCropNameList(newVal)
    }
  },
  created () {
    this.checkApprovedUser()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_name: '',
        warehouse_name_bn: '',
        file_name: '',
        file_name_bn: '',
        total_capacity: '',
        warehouse_type_id: '',
        remarks: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
       await RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
        if (response.success) {
          this.farmerList = response.data
          RestApi.getData(warehouseServiceBaseUrl, wareFarmerQCCertificateList, params).then(response => {
              if (response.success) {
                this.QCCertificateData = this.ListShow(response)
                this.paginationData(response.data)
                this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
              } else {
                this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
              }
          })
        } else {
          this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
        }
      })
    },
   ListShow (data) {
    const farData = this.farmerList.filter(item => item.username === data.farmer_id || item.mobile_no === data.farmer_id)
    let tmpData = {}
    let tmpRegion = {}
    let tmpData2 = {}
    let tmpUpzila = {}
    let tmpWareHouse = {}
    tmpRegion = this.$store.state.ExternalUserIrrigation.warehouse.regionList.find(orgItem => orgItem.value === farData[0].region_id)
    tmpData = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(orgItem => orgItem.value === farData[0].division_id)
    tmpData2 = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(orgItem => orgItem.value === farData[0].district_id)
    tmpUpzila = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(orgItem => orgItem.value === farData[0].upazilla_id)
    tmpWareHouse = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(orgItem => orgItem.value === farData[0].warehouse_id)
    const listData = data.data.map(item => {
        const orgData = { warehouseName: tmpWareHouse.text, warehouseName_bn: tmpWareHouse.text_bn, region_name: tmpRegion.text_en, region_name_bn: tmpRegion.text_bn, division_name: tmpData.text, division_name_bn: tmpData.text_bn, distict_name: tmpData2.text, distict_name_bn: tmpData2.text_bn, upzila_name: tmpUpzila.text, upzila_name_bn: tmpUpzila.text_bn, farmer_name: farData[0].name, farmer_name_bn: farData[0].name_bn, farmer_land_area: farData[0].land_area }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    itemStatus (item) {
      return item.status
    },
    getCropNameList (cropTypeId = null) {
    const commodityNameList = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList
      if (cropTypeId) {
        return commodityNameList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return commodityNameList
    },
    edit (item) {
        this.testId = item.id
        this.farmer_id = item.farmer_id
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
            this.loadData()
        } else {
            this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
