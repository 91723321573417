<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerAction>
                <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
                </b-button>
          </template>
          <template v-slot:body>
            <b-overlay>
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id">
                          {{ $t('externalUserIrrigation.sossho_gudam_rin_karzokrom') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-col md="12" class="table-responsive">
                  <div>
                    <p v-bind:style="{ color: activeColor, fontSize: fontSize + 'px' }">{{ $t('warehouseFarmer.warehouse_name') }}: {{detailsData.warehouseName}} <span style="padding-left:30px;"> {{ $t('warehouseFarmer.date') }}: {{ detailsData.date|dateFormat }}</span>
                    <span style="padding-left:60px;">{{ $t('warehouseFarmer.father_name') }}: {{ detailsData.fatherName }}</span>
                        <span style="padding-left:70px;">{{ $t('warehouseFarmer.district') }}: {{ detailsData.districtName }}</span><br>
                        <span style="padding-left:0px;"> {{ $t('warehouseFarmer.today_items') }}: <b>{{ detailsData.cropName }}</b></span>
                        <span style="padding-left:0px;"> {{ $t('warehouseFarmer.submitToWare') }}</span></p><br>
                      <b-table-simple border="3" hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('warehouseFarmer.sack_quantity') }}</b-th>
                            <b-th>{{ $t('warehouseFarmer.gain_description') }}</b-th>
                            <b-th>{{ $t('warehouseFarmer.sackNo') }}</b-th>
                            <b-th colspan="2" scope="colgroup" style="text-align: center"> {{ $t('warehouseFarmer.weight') }}</b-th>
                          </b-tr>
                           <b-tr>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col">{{ $t('warehouseFarmer.quintal') }}</th>
                              <th scope="col">{{ $t('warehouseFarmer.kilogram') }}</th>
                          </b-tr>
                          <b-tr>
                             <th scope="col">
                               {{ $i18n.locale === 'bn' ? $n(detailsData.sack_quantity) : detailsData.sack_quantity }}
                              </th>
                              <th scope="col">
                                 {{ detailsData.cropTypeName }} >>  {{ detailsData.cropName }}</th>
                              <th scope="col">
                                 {{ $i18n.locale === 'bn' ? $n(detailsData.total_quantity) : detailsData.total_quantity }}
                              </th>
                              <th scope="col">
                                 {{ $i18n.locale === 'bn' ? $n(detailsData.total_quantity) : detailsData.total_quantity }}
                              </th>
                              <th scope="col"> </th>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                      <div class="receipt-footer">
                        <b-row>
                          <b-col class="left">
                            <p></p>
                            <p>{{ $t('warehouseFarmer.application_loan_rate') }}: ............................................</p>
                            <p>{{ $t('warehouseFarmer.in_words') }}</p>
                            <p><vue-qrcode :value="$t('warehouseFarmer.date') +' : '+ detailsData.date + ' ' + $t('warehouseFarmer.stockAmount') + ' (' + $t('warehouseFarmer.quintal') + ') : ' + $n(detailsData.total_quantity)" /></p>
                          </b-col>
                          <b-col class="right">
                            <p>{{ $t('warehouseFarmer.total') }}: ...........................</p>
                            <p class="text-right">
                              <span class="keeper">{{ $t('warehouseFarmer.warehouse_kepper') }}</span>
                            </p>
                          </b-col>
                        </b-row>
                      </div>
                  </div>
                </b-col>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { wareFarmerQCCertificateShowDetails } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
import VueQrcode from 'vue-qrcode'
Vue.use(excel)

export default {
  props: ['id', 'farmer'],
  components: {
    ListReportHead,
    VueQrcode
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      deliveryReceipt: '',
      search: {
        org_id: 13
      },
      detailsData: {
        sack_quantity: '',
        total_quantity: '',
        cropTypeName: '',
        cropName: '',
        warehouseName: '',
        date: '',
        fatherName: '',
        districtName: ''
      },
      qcData: [],
      farmerList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      ListReportHead: ListReportHead,
      activeColor: 'black',
      fontSize: 12
    }
  },
  created () {
    this.loadData()
  },
  computed: {
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.product_type'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.product_name'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.land_area'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.delivery_date'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.receipt'), class: 'text-left' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'warehouse_name_bn' },
            { key: 'commodity_group_name' },
            { key: 'commodity_name_bn' },
            { key: 'farmer_land_area' },
            { key: 'delivery_date' },
            { key: 'receipt' }
          ]
        } else {
          keys = [
            { key: 'index' },
            { key: 'sack_quantity' },
            { key: 'crop_type_id' },
            { key: 'total_quantity' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          this.farmerList = response.data
        }
       const result = await RestApi.getData(warehouseServiceBaseUrl, `${wareFarmerQCCertificateShowDetails}/${this.$props.id}`)
      if (result.success) {
        this.deliveryReceipt = result
        this.qcData = this.ListShow(result.data)
      }
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
    },
    ListShow (data) {
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.detailsData.date = date
      let tmpFarmerData = {}
      let tmpCropType = {}
      let tmpCrop = {}
      let tmpDistrict = {}
      let tmpwarehouse = {}
      const listData = data.map(item => {
        this.detailsData.sack_quantity = item.sack_quantity
        this.detailsData.total_quantity = item.total_quantity
        tmpCropType = this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.find(orgItem => orgItem.value === item.crop_type_id)
        tmpCrop = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.find(orgItem => orgItem.value === item.crop_id)
        tmpFarmerData = this.farmerList.find(farmer => farmer.username === item.farmer_id || farmer.mobile_no === item.farmer_id)
        tmpwarehouse = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(item => item.value === tmpFarmerData.warehouse_id)
        tmpDistrict = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(orgItem => orgItem.value === tmpFarmerData.district_id)
        this.detailsData.cropTypeName = this.$i18n.locale === 'en' ? tmpCropType.text : tmpCropType.text_bn
        this.detailsData.cropName = this.$i18n.locale === 'en' ? tmpCrop.text : tmpCrop.text_bn
        this.detailsData.warehouseName = this.$i18n.locale === 'en' ? tmpwarehouse.text : tmpwarehouse.text_bn
        this.detailsData.fatherName = this.$i18n.locale === 'en' ? tmpFarmerData.father_name : tmpFarmerData.father_name_bn
        this.detailsData.districtName = this.$i18n.locale === 'en' ? tmpDistrict.text : tmpDistrict.text_bn
        const cropDetails = this.$i18n.locale === 'en' ? tmpCropType.text + ' >> ' + tmpCrop.text : tmpCropType.text_bn + ' >> ' + tmpCrop.text_bn
        const orgData = { farmer_name: tmpFarmerData.name, farmer_name_bn: tmpFarmerData.name_bn, farmer_land_area: tmpFarmerData.land_area, cropType: cropDetails }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    pdfExport () {
      const pdfData = this.getPdfData()
      const rowData = []
      const warehouse = this.detailsData.warehouseName
      const date = this.detailsData.date
      const father = this.detailsData.fatherName
      const district = this.detailsData.districtName
      const item = this.detailsData.cropName
      const header = [
          { text: this.$t('warehouseFarmer.warehouse_name'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: warehouse },
          { text: this.$t('warehouseFarmer.date'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: date },
          { text: this.$t('warehouseFarmer.father_name'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: father },
          { text: this.$t('warehouseFarmer.district'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: district },
          { text: this.$t('warehouseFarmer.today_items'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: item }, { text: this.$t('warehouseFarmer.submitToWare'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }
      ]
       // footer part
      const totallist = [
        { text: '' }, { text: '' }, { text: this.$t('warehouseFarmer.total') + ' .........................', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [-150, 0, 0, 8] }
      ]
      const applicationlist = [
         { text: this.$t('warehouseFarmer.application_loan_rate') + ' ...........................................................................................', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' }, { text: '' }
      ]
       const wordsList = [
         { text: this.$t('warehouseFarmer.in_words'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' }, { text: '' }
      ]
      const warehousekeeper = [
         { text: '' }, { text: '' }, { text: this.$t('warehouseFarmer.warehouse_kepper') + ' ..............................', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [50, 0, 0, 0] }
      ]

      const labels = [
          { text: this.$t('warehouseFarmer.sack_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseFarmer.gain_description'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseFarmer.sackNo'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseFarmer.weight'), colSpan: 2, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: '' }
        ]
        const labels1 = [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: this.$t('warehouseFarmer.quintal'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('warehouseFarmer.kilogram'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header],
        footer: [totallist, applicationlist, wordsList, warehousekeeper]
      }
      rowData.unshift(labels, labels1)
      rowData.push(pdfData)
      const reportTitle = this.$i18n.locale === 'en' ? 'QC Certificate' : 'কিউসি সনদপত্র'
      const columnWids = ['20%', '20%', '20%', '20%', '20%']
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData)
    },
    getPdfData () {
      const cropName = this.$i18n.locale === 'bn' ? this.$t(this.detailsData.cropName) : this.detailsData.cropName
      const cropType = this.detailsData.cropTypeName
        const delivery = [
            { text: this.$n(this.detailsData.sack_quantity) },
            { text: cropType + ' >> ' + cropName },
            { text: this.$n(this.detailsData.total_quantity) },
            { text: this.$n(this.detailsData.total_quantity) },
            { text: '' }
          ]
        return delivery
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
  .centered-input >>> input {
      text-align: center
  }
  .total {
   margin-left: 120px;
 }
  .receipt-footer{
   color: #000;
   margin-top: -20px;
 }
 .keeper{
  border-top: 1px dashed black;
  margin-top:-120px;
 }

</style>
